<template>
  <div class="homepage-banner">
    <img id="banner-background" src="../../assets/banner-background.png" />
    <div class="banner-content">
      <div class="banner-text-container">
        <span>Download Scanner+ now & scan, edit, translate, sign or share your documents!</span>
        <store-badges id="banner-badge-container" style="margin: 1vw 0 0 0;" />
      </div>
      <div class="spacer" />
      <img id="banner-image" src="@/assets/banner-phone.png" />
    </div>
  </div>
</template>
<script>

import StoreBadges from "@/components/common/StoreBadges";

export default {
  name: "HomepageBanner",
  components: {
    "store-badges": StoreBadges
  }
}
</script>

<style scoped>
.homepage-banner {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  margin-top: 200px;
  box-sizing: border-box;
}
.banner-content {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}
.banner-text-container {
  display: flex;
  width: 25vw;
  min-width: 25vw;
  flex-shrink: 0;
  height: 68%;
  padding: 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-text-container span {
  font-weight: 600;
  font-size: 1.7vw;
  line-height: 150%;
  color: #FFFFFF;
}
#banner-badge-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}
.spacer {
  flex-basis: 20px;
  flex-grow: 1;
  max-width: 135px;
}
#banner-image {
  height: 30vw;
  flex-shrink: 0;
  transform: translateY(-5%);
}
#banner-background {
  position: absolute;
  left: 0;
  top: 0;
  height: 68%;
  width: 100%;
  transform: translateY(22%);
}
</style>